import React from "react";
import remark from "remark";
import parse from "remark-parse";
import remarkToreact from 'remark-react';
import remarkAttr from 'remark-attr';



const JSONTable = props => { 
  const getJSONFileName = props.data.jsonFile;

 
  const jsonData = {
    qrcode: require("../../../functions/data/json-data-tables/qr-code-versions.json"),
  } 

  // get the jsonFile needed
  const jsonFile = jsonData[getJSONFileName];
    console.log(jsonFile)
  return (
    <div
      style={{
        textAlign: 'center',
        paddingBottom: '1.5rem'
      }}>
      <p className='is-size-9 has-text-weight-bold'>
        {props.title}
      </p>
      <div className={`${props.data.isScrollable ? "table-container" : null}`}>
        <table className="table">
          {props.data.header &&
  
            <thead>
              <tr>
                {props.data.header.map(header => (
                  <th className="sticky-thc"  style={{backgroundColor: `${header.color}`, color: `${header.textColor}`, textAlign: `${ header.align? header.align: "left"}`}}>
                    {header.cell !== null &&
                      <>
                        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(header.cell).contents}
                      </>
                    }
                  </th>
                ))}
              </tr>
            </thead>

          }
          {props.data.footer &&
            <tfoot>
              <tr>
                {props.data.footer.map(footer => (
                  <th style={{backgroundColor: `${footer.color}`, color: `${footer.textColor}`}}>
                    {footer.cell !== null &&
                      <>
                        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(footer.cell).contents}
                      </>
                    }
                  </th>
                ))}
              </tr>
            </tfoot>
          }
          <tbody>
            {jsonFile.map(rows => (
              <tr>
                  {props.data.header.map((item)=>{ 
                  return(
                    <td> 
                      {Object.prototype.toString.call( rows[item.key]) === '[object Array]' ? (
                          rows[item.key].map(arrayItem=>{
                          return(
                            <>
                            {Number.isInteger(arrayItem) ? (<div style={{textAlign: item.align ? item.align: "right"}}>{new Intl.NumberFormat('en-US').format(arrayItem)}</div>):(<div style={{textAlign:item.align ? item.align:"left"}}><p>{arrayItem}</p></div>) }
              
                            </>
                          )
                          }))
                        :
                          (Number.isInteger(rows[item.key]) ? ( <div style={{textAlign: item.align ? item.align: "right"}}>{rows[item.key]}</div> ):<div style={{textAlign:item.align ? item.align:"left"}}> <p>{rows[item.key] }</p></div> )}
                        
                        
                    </td>)
                    
                  })}
                  
              </tr>
            ))}
          </tbody>
        </table>
      {props.note &&
        <p>
          {props.note}
        </p>
      }
      </div>
    </div>
  );
};

export default JSONTable;