import React from "react";
import { Helmet } from "react-helmet";
import "../../../src/components/all.sass";
import { useSiteMetadata } from "../../../src/hooks/use-site-metadata";
import { withPrefix } from "gatsby";

import Footer from "mcc-cms/main/Footer";
import Navbar from "../main/Navbar";

const TemplateWrapper = ({ langKey, slug, children }) => {
  const { title, description, logo } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}files/favicon/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${withPrefix("/")}files/favicon/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${withPrefix("/")}files/favicon/favicon-16x16.png`}
        />
        {/* <link
          rel="manifest"
          href={`${withPrefix("/")}files/favicon/site.webmanifest`}
        /> */}
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}files/favicon/safari-pinned-tab.svg`}
          color="#f8971d"
        />
        <link
          rel="shortcut icon"
          href={`${withPrefix("/")}files/favicon/favicon.ico`}
        />
        <meta
          name="apple-mobile-web-app-title"
          content={description}
        />
        <meta name="application-name" content={description} />
        <meta name="msapplication-TileColor" content="#f8971d" />
        <meta
          name="msapplication-config"
          content={`${withPrefix("/")}files/favicon/browserconfig.xml`}
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="manifest"
          href={`${withPrefix("/")}files/favicon/manifest.json`}
        />
      </Helmet>
      <div style={{
        position: "relative",
        minHeight: "100vh",
      }}>
        <Navbar
          langKey={langKey}
          slug={slug}
          logo={logo}
        />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default TemplateWrapper;
