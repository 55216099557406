import React from "react";
import Chunk from "./Chunk";

const OneColumnContent = props => {
  return (
    <>
      {props.columns.columnOne !== null &&
        <Chunk
          chunk={props.columns.columnOne.chunk}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }
    </>
  );
};

export default OneColumnContent;
