import React, { useState, useEffect  } from "react";
import { Link } from "gatsby";
import remark from "remark";
import parse from "remark-parse";
import remarkToreact from 'remark-react';
import remarkAttr from 'remark-attr';
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import Table from "mcc-cms/columns/Table";
import Accordion from "mcc-cms/columns/Accordion";
import Form from "./Form";
import Banner from "mcc-cms/columns/Banner";
import Carousel from "mcc-cms/columns/Carousel";
import BlogRoll from 'mcc-cms/columns/BlogRoll';
import Jumbotron from './Jumbotron';
import Map from 'mcc-cms/columns/Map';
import CurrencyConverter from 'mcc-cms/columns/CurrencyConverter';
import BuildDate from 'mcc-cms/columns/BuildDate';
import Checkout from 'mcc-cms/columns/Checkout';
import Calculate from 'mcc-cms/columns/Calculate';
import JSONTable from "./JSONTable";

const shortcodes = { CurrencyConverter, BuildDate, Checkout, Calculate }

const Chunk = props => {
  const minWidth = 1200;
  const initMobile =()=>{
    if(typeof document !== "undefined"){
      console.log(window.innerWidth)
      return (window.innerWidth <= minWidth)
    }
  }  
  const [isMobile, setMobile] = useState(initMobile);
    const updateMedia = () => {
      if (typeof document !== "undefined") {
        setMobile(window.innerWidth <= minWidth)
      }
    }

  useEffect(()=>{
    if (typeof document !== "undefined"){
      window.addEventListener("resize", updateMedia);
      return ()=> window.removeEventListener("resize", updateMedia);
    }
  });

  return (
    <>
      {props.chunk.map(chunk => (
        <>
          {/* Text */}
          {chunk.type === "text" &&
            <div
              className={`content ${!!chunk.size ? chunk.size : "is-size-13"}`}
              style={{
                textAlign: `${chunk.align}`,
                paddingBottom: `${!!chunk.paddingBottom ? (chunk.paddingBottom + "rem") : "2rem"}`,
              }}
            >
              {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(chunk.value).contents}
            </div>
          }

          {/* FullText */}
          {chunk.type === "fullText" &&
            <>
              <div className={`full-text-center`} style={{paddingTop: "5px",  height: chunk.hasButton ? "60%" : "100%"  }}>
                {
                  (isMobile) ? 
                    <p style={{padding: "2rem", textAlign:`${chunk.align}`}}>{remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(chunk.value).contents}</p>
                  :(
                    <p className={`content ${!!chunk.size ? chunk.size : "is-size-13"}`} style={{paddingLeft: "5rem", paddingRight: "5rem", textAlign:`${chunk.align}` }}>{remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(chunk.value).contents}</p>
                  )
                }
              </div>
              <div>
                {
                  chunk.hasButton ? 
                  <div className="content"
                    style={{
                      textAlign: "center",
                      paddingBottom: '1.5rem',
                  }}
                  >
                  <Link
                    to={`${props.langKey === "en" ? "" : props.langKey}${chunk.link}`}
                    className={`button is-dark ${(isMobile) ? "is-small" : "is-large" }`}
                  >
                    {chunk.buttonLabel}
                  </Link> 
                  </div> : null
                }
              </div>
            </>
          }

          {/* Image */}
          {chunk.type === "image" &&
            <div
              className="content"
              style={{
                textAlign: `${chunk.align}`,
                paddingBottom: "1.5rem"
              }}
            >
              {chunk.useMap !== null ? (
                <img
                  src={!!chunk.image.childImageSharp ? chunk.image.childImageSharp.fluid.src : chunk.image}
                  width={chunk.width}
                  height={chunk.height}
                  alt={chunk.alt}
                  useMap={"#" + chunk.useMap.mapName}
                />
              ) : (
                <img
                  src={!!chunk.image.childImageSharp ? chunk.image.childImageSharp.fluid.src : chunk.image}
                  width={chunk.width}
                  height={chunk.height}
                  alt={chunk.alt}
                />
              )}
              {chunk.useMap !== null ? (
                <map name={chunk.useMap.mapName}>
                  {chunk.useMap.area.map(area => (
                    <area
                      shape={area.shape}
                      coords={area.coords}
                      href={area.link}
                      alt={area.alt}
                    />
                  ))}
                </map>
              ) : null}
            </div>
          }
          
          {/* ImageFull */}
          {chunk.type === "imageFull" &&
            <div
              className="image-container"
              style={{
                textAlign: `${chunk.align}`,      
                paddingTop: "5px"          
              }}
            >
              {chunk.useMap !== null ? (
                <img
                  src={!!chunk.image.childImageSharp ? chunk.image.childImageSharp.fluid.src : chunk.image}
                  width={chunk.width}
                  height={chunk.height}
                  alt={chunk.alt}
                  useMap={"#" + chunk.useMap.mapName}
                />
              ) : (
                <img
                  src={!!chunk.image.childImageSharp ? chunk.image.childImageSharp.fluid.src : chunk.image}
                  width={chunk.width}
                  height={chunk.height}
                  alt={chunk.alt}
                />
              )}
              {chunk.useMap !== null ? (
                <map name={chunk.useMap.mapName}>
                  {chunk.useMap.area.map(area => (
                    <area
                      shape={area.shape}
                      coords={area.coords}
                      href={area.link}
                      alt={area.alt}
                    />
                  ))}
                </map>
              ) : null}
            </div>
          }

          {/* Button */}
          {chunk.type === "button" &&
            <div
              className="content"
              style={{
                textAlign: `${chunk.align}`,
                paddingBottom: '1.5rem',
              }}
            >
              <Link
                to={`${props.langKey === "en" ? "" : props.langKey}${chunk.link}`}
                className="button is-dark is-small"
              >
                {chunk.value}
              </Link>
            </div>
          }
          {/* JSON Table*/}
          {chunk.type === "jsonTable" &&
            <div
              className="content"
              style={{paddingBottom: "1.5rem"}}
            >
              <JSONTable
                title={chunk.title !== null ? chunk.title : null}
                data={chunk}
              />
            </div>
          }
          {/* Table */}
          {chunk.type === "table" &&
            <div
              className="content"
              style={{paddingBottom: "1.5rem"}}
            >
              <Table
                title={chunk.title !== null ? chunk.title : null}
                data={chunk}
              />
            </div>
          }

          {/* Table - New */}
          {chunk.type === "tableNew" &&
            <div
              className={`table is-striped`}
              style={{paddingBottom: "1.5rem"}}
            >
              {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(chunk.value).contents}
            </div>
          }

          {/* Accordion */}
          {chunk.type === "accordion" &&
            <div
              className="content"
              style={{
                textAlign: `${chunk.align}`,
                paddingBottom: "1.5rem",
              }}
            >
              {chunk.entries.map(entries => (
                <Accordion
                  title={entries.title}
                  content={entries.content}
                />
              ))}
            </div>
          }

          {/* Form */}
          {chunk.type === "form" &&
            <div
              className="content"
              style={{paddingBottom: "1.5rem",}}
            >
              <Form
                data={chunk}
                langKey={props.langKey}
              />
            </div>
          }

          {/* Banner */}
          {chunk.type === "banner" &&
            <div
              className="content"
              style={{paddingBottom: "1.5rem",}}>
              <Banner
                data={chunk}
                langKey={props.langKey}
              />
            </div>
          }

          {/* Carousel */}
          {chunk.type === "carousel" &&
            <div
              style={{paddingBottom: "1.5rem",}}
            >
              <Carousel
                data={chunk}
              />
            </div>
          }

          {/* Blog Roll */}
          {chunk.type === "blogRoll" &&
            <div
              style={{paddingBottom: "1.5rem",}}
            >
              <BlogRoll
                langKey={props.langKey}
                isHome={chunk.isHome}
              />
            </div>
          }

          {/* Jumbotron */}
          {chunk.type === "jumbotron" &&
            <Jumbotron
              data={chunk}
            />
          }

          {/* Map */}
          {chunk.type === "map" &&
            <Map
              data={chunk}
            />
          }

          {/* HTML */}
          {chunk.type === "html" &&
            <div
              className={`content ${!!chunk.size ? chunk.size : "is-size-13"}`}
              style={{
                textAlign: `${chunk.align}`,
                paddingBottom: `${!!chunk.paddingBottom ? (chunk.paddingBottom + "rem") : "2rem"}`,
              }}
              dangerouslySetInnerHTML={{__html: `${chunk.value}`}}
            />
          }

          {/* Include File */}
          {chunk.type === "include" &&
            <>
              {props.reusable.map(reusable => (
                <>
                  {reusable.node.frontmatter.title === chunk.value &&
                    <div
                      className={`content ${!!chunk.size ? chunk.size : "is-size-13"}`}
                      style={{
                        textAlign: `${chunk.align}`,
                        paddingBottom: `${!!chunk.paddingBottom ? (chunk.paddingBottom + "rem") : "2rem"}`,
                      }}
                    >
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer>
                          {reusable.node.body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  }
                </>
              ))}
            </>
          }
        </>
      ))}
    </>
  );
};

export default Chunk